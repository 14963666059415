import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css'
import {Jumbotron} from 'react-bootstrap';

interface WelcomeProps {
  isAuthenticated: boolean;
  authButtonMethod: any;
  user: any;
}

interface WelcomeState {
  isOpen: boolean;
}

class Landing extends React.Component<WelcomeProps, WelcomeState> {
  render() {
    return (
      <Jumbotron>
        <h4>Welcome to LandPortal</h4>
        <p className="lead">
          One place to serve all your needs related to Greening Australia!
        </p>
      </Jumbotron>
    );
  }
}

export default Landing;
