import React from 'react';
import logo from '../../Assets/Logos/logo.svg';

import 'bootstrap/dist/css/bootstrap.min.css';

interface Props {}

interface State {
  isOpen: boolean //KS: Can define any specific state params
}

class Header extends React.Component<Props, State> {

  constructor(props: Props, state: State) {
    super(props);

    //KS: Set default state params as required
    this.state = {
      isOpen: false
    };
  }

  render() {
    return (
        <React.Fragment>
        <header className="App-Header">
            <img src={logo} className="App-logo" alt="logo" />
        </header>
        </React.Fragment>
    );
  }
}

export default Header;
