import React, {Component} from 'react';
import IAuthProps, {AuthProviderState} from './IAuthProps';

import dotenv from 'dotenv';

import { UserAgentApplication, Configuration, AuthResponse, AuthError } from 'msal';
import {msalConfig, loginRequest, tokenRequest} from './authConfig';
import {getUserDetails} from './GraphService';

export default function withAuthProvider<T extends React.Component<IAuthProps>>
(WrappedComponent: new (props: IAuthProps, context?: any) => T): React.ComponentClass {
return (
  class extends Component<any, AuthProviderState> {
    private userAgentApplication: UserAgentApplication;
    private appConfig: Configuration;

    constructor(props: any) {
      super(props);
      this.state = {
        error: null,
        isAuthenticated: false,
        user: {}
      };

      dotenv.config();
      console.log("Redirect URI:", process.env.REACT_APP_REDIRECT_URI);
      console.log("Post Redirect URI:", process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI);

      this.appConfig = msalConfig as Configuration;
      this.appConfig.auth.redirectUri = process.env.REACT_APP_REDIRECT_URI;
      this.appConfig.auth.postLogoutRedirectUri = process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI;

      // Initialize the MSAL application object
      this.userAgentApplication = new UserAgentApplication(this.appConfig);
      this.userAgentApplication.handleRedirectCallback( (err, res)  => {
        this.handleRedirectAuthResponse(err, res);
      });
    }

    componentDidMount() {
      // If MSAL already has an account, the user
      // is already logged in
      const authUser = this.userAgentApplication.getAccount();
      if (authUser && authUser.name) {
        
        this.setState({
        isAuthenticated: (authUser !== null),
        user: authUser,
        error: null});
      }
    }

    render() {
      return (
        <WrappedComponent
        error={this.state.error}
        isAuthenticated={this.state.isAuthenticated}
        user={this.state.user}
        login={() => this.loginViaRedirect()}
        logout={() => this.logout()}
        getAccessToken={(scopes: string[]) => this.getAccessToken(scopes)}
        getTokens={(scopes: string[]) => this.getTokens(scopes)}
        setError={(message: string, debug: string) => this.setErrorMessage(message, debug)}
        {...this.props} />
      );
    }

    async loginViaPopup() {
      try {
        console.log("Got popup login request...");
        // Login via popup
        var authResponse = await this.userAgentApplication.loginPopup(loginRequest);

        // After login, get the user's profile
        if (authResponse && authResponse.idToken) {

          // Get the user's profile from Graph
          //var authUser = await getUserDetails(authResponse.accessToken);
          // KS: Check if we have received an error while calling getuserDetails
          //if (accounts.length <= 0) throw new Error('login_required');

          const authUser = this.userAgentApplication.getAccount();
          if (authUser && authUser.name) {
            
            this.setState({
            isAuthenticated: (authUser !== null),
            user: authUser,
            error: null});
          }

          //KS: For B2C, we cannot call GraphAPI, it needs to be done via a call to the api (with required scopes)
          // const accessToken = await this.getAccessToken(tokenRequest.scopes);
          // if(accessToken && accessToken.length > 0)
          // {
          //   const userDetails = await getUserDetails(accessToken);
          //   if(userDetails && userDetails.name) {
          //     this.setState({
          //       isAuthenticated: (authUser !== null),
          //       user: authUser,
          //       error: null});
          //   }
          // }
        }
      }
      catch (err) {

        // // Error handling
        // if (error.errorMessage) {
        //     // Check for forgot password error
        //     // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
        //     if (error.errorMessage.indexOf("AADB2C90118") > -1) {
        //     myMSALObj.loginPopup(b2cPolicies.authorities.forgotPassword)
        //         .then(loginResponse => {
        //         console.log(loginResponse);
        //         window.alert("Password has been reset successfully. \nPlease sign-in with your new password.");
        //         })
        //     }
        // }

        this.setState({
          isAuthenticated: false,
          user: {},
          error: this.normalizeError(err)
        });

        console.log("An error occurred while handling login via popup!", err);

      }
    }

    loginViaRedirect() {
      try {
        console.log("Got redirect login request...");
        // Login via popup
        this.userAgentApplication.loginRedirect(loginRequest);
      }
      catch(err) {
        console.log("An error occurred while calling login via redirect!", err);
      }
    }

    handleRedirectAuthResponse(error: AuthError, authResponse? : AuthResponse) {
      
      try {

        // After login, get the user's profile
        if (authResponse && authResponse.idToken) {

          // Get the user's profile from Graph
          //var authUser = await getUserDetails(authResponse.accessToken);
          // KS: Check if we have received an error while calling getuserDetails
          //if (accounts.length <= 0) throw new Error('login_required');

          const authUser = this.userAgentApplication.getAccount();
          if (authUser && authUser.name) {
            
            this.setState({
            isAuthenticated: (authUser !== null),
            user: authUser,
            error: null});
          }

          //KS: For B2C, we cannot call GraphAPI, it needs to be done via a call to the api (with required scopes)
          // const accessToken = await this.getAccessToken(tokenRequest.scopes);
          // if(accessToken && accessToken.length > 0)
          // {
          //   const userDetails = await getUserDetails(accessToken);
          //   if(userDetails && userDetails.name) {
          //     this.setState({
          //       isAuthenticated: (authUser !== null),
          //       user: authUser,
          //       error: null});
          //   }
          // }
        }
      }
      catch (err) {

        // // Error handling
        // if (error.errorMessage) {
        //     // Check for forgot password error
        //     // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
        //     if (error.errorMessage.indexOf("AADB2C90118") > -1) {
        //     myMSALObj.loginPopup(b2cPolicies.authorities.forgotPassword)
        //         .then(loginResponse => {
        //         console.log(loginResponse);
        //         window.alert("Password has been reset successfully. \nPlease sign-in with your new password.");
        //         })
        //     }
        // }

        this.setState({
          isAuthenticated: false,
          user: {},
          error: this.normalizeError(err)
        });

        console.log("An error occurred while handling login via redirect callback!", err);
      }
    }



    logout() {
      this.userAgentApplication.logout();
    }

    async getAccessToken(scopes: string[]): Promise<string> {
      try {
        // const accounts = this.userAgentApplication.getAllAccounts();
        // if (accounts.length <= 0) 
        //   throw new Error('login_required');

        // // Get the access token silently
        // // If the cache contains a non-expired token, this function
        // // will just return the cached token. Otherwise, it will
        // // make a request to the Azure OAuth endpoint to get a token
        // var silentResult = await this.userAgentApplication
        //   .acquireTokenSilent({
        //     scopes: scopes,
        //     account: accounts[0]
        //   });

        const userAccount = this.userAgentApplication.getAccount();
        if (!userAccount || !userAccount.name) 
           throw new Error('login_required');

        // Get the access token silently
        // If the cache contains a non-expired token, this function
        // will just return the cached token. Otherwise, it will
        // make a request to the Azure OAuth endpoint to get a token
        var silentResult = await this.userAgentApplication.acquireTokenSilent({ scopes: tokenRequest.scopes});

        if(!silentResult || !silentResult.accessToken || !(silentResult.accessToken.length > 0))
        {
          return "";
        }

        return silentResult.accessToken;

      } catch (err) {
        // If a silent request fails, it may be because the user needs
        // to login or grant consent to one or more of the requested scopes
        if (this.isInteractionRequired(err)) {
          var interactiveResult = await this.userAgentApplication
            .acquireTokenPopup({
              scopes: scopes
            });

          return (
            interactiveResult.accessToken
          );
        } else {
          throw err;
        }
      }
    }

    async getTokens(scopes: string[]): Promise<any> {
      try {
        // const accounts = this.userAgentApplication.getAllAccounts();

        // if (accounts.length <= 0) throw new Error('login_required');
        // // Get the access token silently
        // // If the cache contains a non-expired token, this function
        // // will just return the cached token. Otherwise, it will
        // // make a request to the Azure OAuth endpoint to get a token
        // var silentResult = await this.userAgentApplication
        //   .acquireTokenSilent({
        //     scopes: scopes,
        //     account: accounts[0]
        //   });

        const userAccount = this.userAgentApplication.getAccount();

        if (!userAccount || !userAccount.name) 
          throw new Error('login_required');
        // Get the access token silently
        // If the cache contains a non-expired token, this function
        // will just return the cached token. Otherwise, it will
        // make a request to the Azure OAuth endpoint to get a token
        var silentResult = await this.userAgentApplication.acquireTokenSilent({ scopes: scopes});

        //return silentResult.accessToken;
        // KS: Modified to return both tokens            
        return  {
          accessToken : silentResult.accessToken,
          idToken : silentResult.idToken
        }

      } catch (err) {
        // If a silent request fails, it may be because the user needs
        // to login or grant consent to one or more of the requested scopes
        if (this.isInteractionRequired(err)) {
          var interactiveResult = await this.userAgentApplication.acquireTokenPopup({scopes: scopes});

          // KS: Modified to return both tokens            
          return  {
            accessToken : interactiveResult.accessToken,
            idToken : interactiveResult.idToken
          }

        } else {
          throw err;
        }
      }
    }

    async getUserProfile(authResponse?: AuthResponse) {

      console.log("Got user profile request...");

      try {
        if(!authResponse)
        {
          authResponse = await this.userAgentApplication.acquireTokenSilent({ scopes: tokenRequest.scopes});
        }

        if (authResponse && authResponse.accessToken) {
          // Get the user's profile from Graph
          var authUser = await getUserDetails(authResponse.accessToken);

          // KS: Check if we have received an error while calling getuserDetails
          //if (accounts.length <= 0) throw new Error('login_required');

          this.setState({
            isAuthenticated: true,
            user: {
              displayName: authUser.displayName,
              email: authUser.mail || authUser.userPrincipalName,
              timeZone: authUser.mailboxSettings.timeZone,
              timeFormat: authUser.mailboxSettings.timeFormat
            },
            error: null
          });
        }
      }
      catch(err) {
        this.setState({
          isAuthenticated: false,
          user: {},
          error: this.normalizeError(err)
        });
      }
    }

    setErrorMessage(message: string, debug: string) {
      this.setState({
        error: { message: message, debug: debug }
      });
    }

    normalizeError(error: string | Error): any {
      var normalizedError = {};
      if (typeof (error) === 'string') {
        var errParts = error.split('|');
        normalizedError = errParts.length > 1 ?
          { message: errParts[1], debug: errParts[0] } :
          { message: error };
      } else {
        normalizedError = {
          message: error.message,
          debug: JSON.stringify(error)
        };
      }
      return normalizedError;
    }

    isInteractionRequired(error: Error): boolean {
      if (!error.message || error.message.length <= 0) {
        return false;
      }

      return (
        error.message.indexOf('consent_required') > -1 ||
        error.message.indexOf('interaction_required') > -1 ||
        error.message.indexOf('login_required') > -1 ||
        error.message.indexOf('no_account_in_silent_request') > -1
      );
    }
  }
);
}