import React from 'react';
import {Link} from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import {Button, Navbar, Nav, NavItem} from 'react-bootstrap'

interface INavbarProps {
  isAuthenticated: boolean;
  authButtonMethod: any;
  user: any;
}

interface INavbarState {
  isOpen: boolean;
}


function AuthNavItem(props: INavbarProps) {
  if (props.isAuthenticated) {
    return (
      <>
      <Nav.Link as={Link} to={"/userprofile"}>My Profile</Nav.Link>
      <NavItem>
      <Button
        onClick={props.authButtonMethod}
        className="btn-link nav-link border-0"
        color="link">Sign Out</Button>
      </NavItem>
      </>
    );
  }

  // Not authenticated, return a sign in link
  return (
    <NavItem>
      <Button
        onClick={props.authButtonMethod}
        className="btn-link nav-link border-0"
        color="link">Sign In</Button>
    </NavItem>
  );
}


class Menubar extends React.Component<INavbarProps, INavbarState> {

  wrapper: React.RefObject<unknown>;

  constructor(props: INavbarProps, state: INavbarState) {
    super(props);

    //KS: To resolve console warnings
    this.wrapper = React.createRef();

    //KS: Set default state params as required
    this.state = {
      isOpen: false
    };
  }

  render() {

    return (
        <div>
          <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
            <Navbar.Brand as={Link} to={"/home"}>GER-X: LandPortal</Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="mr-auto">
                <Nav.Link as={Link} to={"/about"}>About</Nav.Link>
                <Nav.Link as={Link} to={"/contact"}>Contact</Nav.Link>
                <Nav.Link as={Link} to={"/news"}>News</Nav.Link>
                {this.props.isAuthenticated && (
                <Nav.Link as={Link} to={"/callapi"}>Call API</Nav.Link>
                )}
              </Nav>
              <Nav>              
              <AuthNavItem 
                isAuthenticated={this.props.isAuthenticated}
                authButtonMethod={this.props.authButtonMethod}
                user={this.props.user}
              />
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </div>
    );
  }
}

export default Menubar;
