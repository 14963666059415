import { Configuration } from 'msal';
import apiConfig from './apiConfig';
import b2cPolicies from './b2cpolicies';

/**
 * Config object to be passed to MSAL on creation.
 * For a full list of msal.js configuration parameters, 
 * visit https://azuread.github.io/microsoft-authentication-library-for-js/docs/msal/modules/_configuration_.html
 * 
 * */

/**
 * Prod redirectUri: "https://gerxdev.z8.web.core.windows.net/"
 * */
const msalConfig:Configuration = {
  auth: {
    clientId: "6be5ddae-8beb-430a-96c4-61c68aa0d8b6",
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    validateAuthority: false,
    redirectUri: "https://gerxdev.z8.web.core.windows.net",
    postLogoutRedirectUri: "https://gerxdev.z8.web.core.windows.net",
    navigateToLoginRequestUrl: true
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: true // Set this to "true" to save cache in cookies to address trusted zones limitations in IE (see: https://github.com/AzureAD/microsoft-authentication-library-for-js/wiki/Known-issues-on-IE-and-Edge-Browser)
  }
};

/** 
 * Scopes you enter here will be consented once you authenticate. For a full list of available authentication parameters, 
 * visit https://azuread.github.io/microsoft-authentication-library-for-js/docs/msal/modules/_authenticationparameters_.html
 */
const loginRequest = {
  scopes: ["openid", "profile"],
};

// Add here scopes for access token to be used at the API endpoints.
const tokenRequest = {
  scopes: apiConfig.b2cScopes,  // e.g. ["https://fabrikamb2c.onmicrosoft.com/helloapi/demo.read"]
};

export { msalConfig, loginRequest, tokenRequest }