import React, {Component} from 'react';

//import withAuthProvider, { AuthComponentProps } from '../../Providers/AuthProvider';
import IAuthProps from '../../Containers/Auth/IAuthProps';
import withAuthProvider from '../../Containers/Auth/MsalProvider';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Container} from 'react-bootstrap';

import Menubar from '../Layout/Menubar';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import Home from '../Pages/Home';
import About from '../Pages/About';
import Contact from '../Pages/Contact';
import News from '../Pages/News';
import Logout from '../Pages/Logout';
import Version from '../Pages/Version';
import NotFound from '../Pages/NotFound';

import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Projects from '../Projects/Projects';
import Users from '../Users/Users';
import TestRBS from '../Pages/TestRBS';
import ErrorMessage from '../Auth/ErrorMessage';
import Landing from '../Pages/Landing';
import UserProfile from '../Pages/UserProfile';
import GetDate from '../LandPortalApi/GetDate';

class App extends Component<IAuthProps> {

  render() {

    let error = null;
    if (this.props.error) {
      error = <ErrorMessage
        message={this.props.error.message}
        debug={this.props.error.debug} />;
    }

    return (
      <Router>          
        <Header/>
          <div className="App">
            <Menubar 
            isAuthenticated={this.props.isAuthenticated}
            authButtonMethod={this.props.isAuthenticated ? this.props.logout : this.props.login}
            user={this.props.user} />
            
            <Container>
              {error}
              <Switch>

              <Route path="/" exact render={(props) => (
                  <Landing {...props}
                  isAuthenticated={this.props.isAuthenticated}
                  user={this.props.user}
                  authButtonMethod={this.props.login} />
                )
              } />

              <Route path="/home" render={(props) => (
                  <Home {...props}
                  isAuthenticated={this.props.isAuthenticated}
                  user={this.props.user}
                  authButtonMethod={this.props.login} />
                )
              } />

              <Route path="/about" component={About} />
              <Route path="/contact" component={Contact} />
              <Route path="/news" component={News} />
              <Route path="/version" component={Version} />
              <Route path="/logout" component={Logout} />
              
              <Route path="/userprofile" render={(props) => (
                  <UserProfile {...props}
                  isAuthenticated={this.props.isAuthenticated}
                  user={this.props.user}
                  authButtonMethod={this.props.login} />
                )
              } />

              <Route path="/callapi" render={(props) => (
                  <GetDate {...props}
                  isAuthenticated={this.props.isAuthenticated}
                  user={this.props.user}
                  authButtonMethod={this.props.login} 
                  getAccessToken={this.props.getAccessToken}
                  getTokens={this.props.getTokens}/>
                )
              } />

              <Route path="/test" component={TestRBS} />
              <Route path="/projects/:projectId" component={Projects} />
              <Route path="/users/:userId" component={Users} />
              <Route default component={NotFound} />

              </Switch>
              </Container>
          </div>
        <Footer/>
      </Router>
    );
  }
}

//export default App;
export default withAuthProvider(App);
